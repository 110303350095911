import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import './VideoPlayer.css';

const zhCN = require('video.js/dist/lang/zh-CN.json');

videojs.addLanguage('zh', zhCN);

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    const { name } = this.props;
    return (
      <div>
        <div data-vjs-player>
          <video
            ref={node => {
              this.videoNode = node;
            }}
            className={`video-js ${
              name === 'growthVideo' ? 'growth-video' : ''
            }`}
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
    );
  }
}
